<script setup lang="ts">
withDefaults(
  defineProps<{
    darkMode?: boolean | 'auto'
    text: string
  }>(),
  {
    darkMode: 'auto',
  },
)
</script>

<script lang="ts">
export default {}
</script>

<template>
  <p
    :class="[
      'inline-block',
      // Animation
      'loading-text',
      // Clip background with text
      'text-transparent',
      'bg-gradient-to-r',
      'bg-clip-text',
      // Color
      darkMode === true && 'from-canary-500 via-loading-text-canary to-canary-500',
      (darkMode === false || darkMode === 'auto') && 'from-grape-500 via-loading-text-grape to-grape-500',
      darkMode === 'auto' && 'dark:from-canary-500 dark:via-loading-text-canary dark:to-canary-500',
    ]"
  >
    {{ text }}
  </p>
</template>

<style lang="postcss" scoped>
.loading-text {
  background-size: 200% auto;
  animation: slide 1.5s linear infinite;
  will-change: background-position;
  @keyframes slide {
    to {
      background-position: -200% center;
    }
  }
}
</style>
